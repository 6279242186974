*,
*::after,
*::before {
	padding: 0;
	margin: 0;
	box-sizing: inherit;
}

body {
	box-sizing: border-box;
	font-family: Poppins;
	font-size: var(--font-size);
	text-size-adjust: 100%;
}

:root {
	--font-size: 13px;
}

.ptro-holder-wrapper {
	z-index: 2000;
}

.swal2-container {
	z-index: 1700 !important;
}

.ck.ck-content ul,
.ck.ck-content ol {
	padding-left: 2.666em;
}

@media print {
	body {
		display: flex;
		flex-direction: column;
	}

	html,
	body {
		height: 100%;
	}
}

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-treegrid/styles/material.css";